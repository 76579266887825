<script setup>
import { computed, toRef } from 'vue';

const emit = defineEmits(['select']);

const props = defineProps({
  medicine: {
    type: Object,
    default: () => ({}),
  },
  favorite: {
    type: Boolean,
    default: false,
  },
  systemDefault: {
    type: Boolean,
    default: false,
  },
  userMedicine: {
    type: Boolean,
    default: false,
  },
});

const medicine = toRef(props, 'medicine');
const formulations = computed(() => {
  if (!medicine.value?.formulations) {
    if (medicine.value.formulation) {
      return [{ formulation: medicine.value.formulation }];
    }
    return [];
  }
  return medicine.value.formulations.map((formulation) => {
    if (formulation.formulation) {
      return formulation;
    }
    if (formulation.strength && formulation.form) {
      return {
        ...formulation,
        formulation: `${formulation.strength} ${formulation.form}`
      }
    };
    return '';
  });
})

function select () {
  emit('select', {
    ...medicine.value,
    formulations: formulations.value,
  });
}
</script>

<template>
  <div class="bg-white p-2 rounded-md hover:outline outline-1 cursor-pointer" @click="select">
    <div class="flex justify-between items-start mb-2">
      <div>
        <h3 class="text-lg text-gray-800 font-medium">{{ medicine.genericName }}</h3>
        <p class="text-md text-gray-600">{{ medicine.brandName }}</p>
      </div>
      <div class="flex space-x-2">
        <i v-if="favorite" class="las la-star text-2xl text-yellow-400" title="Favorite"></i>
        <i v-if="systemDefault" class="las la-cogs text-2xl text-grey-500" title="System Default"></i>
        <i v-if="userMedicine" class="las la-user text-2xl text-green-500" title="User Medicine"></i>
      </div>
    </div>
    <div class="text-sm text-gray-700">
      <p><i class="las la-capsules mr-1"></i> Formulations:</p>
      <ul v-if="formulations.length" class="list-disc list-inside pl-4">
        <li v-for="formulation in formulations" :key="formulation.id">
          {{ formulation.formulation }}
        </li>
      </ul>
      <small v-else>No formulations available</small>
    </div>
  </div>
</template>