import { omit } from 'lodash';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useSdk } from '@/composables/use-sdk';
import { useFacilities } from '@/composables/use-facilities';
import { createGlobalCache } from '@/composables/caches';

const MEDICINE_FORMULATIONS_SERVICE_NAME = 'medicine-formulations';
const MEDICINE_SERVICE_NAME = 'medicines';
const MEDICINE_CONFIGURATIONS_SERVICE_NAME = 'medicine-configurations';

function mapMedicineConfigurations (items) {
  return items.map(item => {
    const medicine = item?.$populated?.medicine || {};
    return {
      ...omit(item, ['item.$populated']),
      medicine,
    };
  });
};

const useMedicinesCache = createGlobalCache('medicines');
export function useBffMedicines () {
  const sdk = useSdk();
  const medicinesCache = useMedicinesCache();

  async function listItems (opts) {
    const query = Object.assign({
      $sort: { createdAt: -1 },
      $populate: {
        formulations: {
          service: 'medicine-formulations',
          method: 'find',
          foreignKey: 'medicine',
          localKey: 'id',
        },
      },
    }, opts);

    if (opts?.searchString) {
      query.$or = [
        { genericName: { $regex: opts.searchString, $options: 'i' } },
      ];
    }

    if (opts?.genericName) {
      query.genericName = opts.genericName;
    }

    if (opts?.organization) {
      query.organization = opts.organization;
    }

    if (opts?.createdBy) {
      query.createdBy = opts.createdBy;
    }

    delete query.searchString;

    const cachekey = JSON.stringify(query);
    const cached = medicinesCache.get(cachekey);
    if (cached) {
      return cached;
    }

    const { data, total } = await sdk.list(MEDICINE_SERVICE_NAME, query);

    const mapped = {
      data: data.map(item => {
        const formulations = item.$populated?.formulations || [];
        delete item.$populated;
        return {
          ...item,
          formulations,
        };
      }),
      total,
    };

    medicinesCache.set(cachekey, mapped, 1000 * 60);
    console.warn('medicines -> cached', query, mapped.length);

    return mapped;
  }

  async function createItem (item) {
    return sdk.create(MEDICINE_SERVICE_NAME, item);
  }

  async function removeItem (id) {
    return sdk.delete(MEDICINE_SERVICE_NAME, id);
  }

  return {
    listItems,
    createItem,
    removeItem,
  };
};

const useMedicineConfigurationsCache = createGlobalCache('medicine-configurations');
export function useBffMedicineConfigurations () {
  const sdk = useSdk();
  const { activeFacility } = useFacilities();
  const medicineConfigurationsCache = useMedicineConfigurationsCache();

  async function listItems (opts) {
    const organizationId = opts.organization || activeFacility.value?.id;
    const query = {
      organization: organizationId,
      $sort: { createdAt: -1 },
      $populate: {
        medicine: {
          service: 'medicines',
          localKey: 'medicine',
        },
      },
    };
    const cachekey = JSON.stringify(query);
    const cached = medicineConfigurationsCache.get(cachekey);
    if (cached) {
      return cached;
    }
    const { data, total } = await sdk.list(MEDICINE_CONFIGURATIONS_SERVICE_NAME, query);
    const mapped = {
      data: mapMedicineConfigurations(data),
      total,
    };
    medicineConfigurationsCache.set(cachekey, mapped, 1000 * 60);
    console.warn('medicine-configurations -> cached', query, mapped.length);
    return mapped;
  };

  async function createItem (data) {
    const organizationId = data.organization || activeFacility?.id;

    const payload = {
      ...omit(data, ['organization', '$unset']),
      organization: organizationId,
    };

    return sdk.create(MEDICINE_CONFIGURATIONS_SERVICE_NAME, payload);
  }

  async function updateItem (id, data) {
    const payload = {
      ...omit(data, ['organization', 'id', 'medicine']),
    };
    return sdk.update(MEDICINE_CONFIGURATIONS_SERVICE_NAME, id, payload);
  }

  async function getItem (id) {
    // Fetch the item from the server
    return sdk.get(MEDICINE_CONFIGURATIONS_SERVICE_NAME, id);
  }

  async function removeItem (id) {
    console.warn('id', id);
    return sdk.delete(MEDICINE_CONFIGURATIONS_SERVICE_NAME, id);
  }

  return {
    listItems,
    createItem,
    updateItem,
    getItem,
    removeItem,
  };
};

export function useMedicineFormulations () {
  const sdk = useSdk();

  async function listItems (opts) {
    const query = {
      ...opts,
    };
    const { data } = await sdk.list(MEDICINE_FORMULATIONS_SERVICE_NAME, query);
    return data.map(formulation => {
      if (!formulation?.formulation) return;
      return formulation.formulation;
    }).filter(Boolean);
  };

  return {
    listItems,
  };
};

export const useBffUnifiedMedicinesStore = defineStore('bff-unified-medicines', () => {
  const loading = ref(false);
  const unifiedMedicines = ref({
    medicines: [],
    medicineConfigurations: [],
  });
  
  const { listItems: listMedicines } = useBffMedicines();
  const { listItems: listMedicineConfigurations } = useBffMedicineConfigurations();

  async function unifiedSearch (searchString, { medicineOpts, medicineConfigurationsOpts } = {}) {
    const medicineQuery = Object.assign({
      searchString,
    }, medicineOpts);

    const medicineConfigurationsQuery = Object.assign({
      searchString,
    }, medicineConfigurationsOpts);

    loading.value = true;
    
    const [systemMedicines, medicines, favoriteMedicines] = await Promise.all([
      listMedicines({ organization: null, searchString }),
      listMedicines(medicineQuery),
      listMedicineConfigurations(medicineConfigurationsQuery),
    ]);

    loading.value = false;

    unifiedMedicines.value = {
      systemMedicines,
      medicines,
      favoriteMedicines,
    };
    
    return {
      systemMedicines,
      medicines,
      favoriteMedicines,
    };
  };

  return {
    loading,
    unifiedMedicines,
    unifiedSearch,
  };
});

export const useBffMedicinesStore = defineStore('bff-medicines', () => {
  const loading = ref(false);
  const initialized = ref(false);
  const total = ref(0);
  const items = ref([]);
  const itemsFirstPage = ref([]);
  const itemsFirstPageTotal = ref(0);

  const { listItems: listItemsUncached, createItem: createMedicine, removeItem: removeMedicine } = useBffMedicines();

  async function createItem (data) {
    return createMedicine(data);
  };

  async function removeItem (id) {
    await removeMedicine(id);
    const index = items.value.findIndex(item => item.id === id);
    items.value.splice(index, 1);
  };

  async function listItems (opts) {
    try {
      if (opts?.skipInitialized && initialized.value) {
        console.warn('listItems: already initialized');
        return;
      }
      if (!opts?.skipLoading) loading.value = true;
      opts = Object.assign({
        $limit: 10,
      }, opts);
      delete opts.skipInitialized;
      const res = await listItemsUncached(opts);
      // base page
      if (opts.$page === 1 && !opts.$search) {
        itemsFirstPage.value = res.data;
        itemsFirstPageTotal.value = res.total;
      }
      // filtered/paginated page
      items.value = res.data;
      total.value = res.total;
    } finally {
      initialized.value = true;
      console.info('listItems: done');
      if (!opts?.skipLoading) loading.value = false;
    }
  }

  async function resetFirstPageItems () {
    items.value = itemsFirstPage.value;
    total.value = itemsFirstPageTotal.value;
  };

  return {
    total,
    items,
    loading,
    listItems,
    createItem,
    removeItem,
    resetFirstPageItems,
  };
});
