<script setup>
import { ref, watch, computed, nextTick } from 'vue';
import { debounce } from 'lodash';
import { useFacilities } from '@/composables/use-facilities';
import { storeToRefs } from 'pinia';
import { useBffUnifiedMedicinesStore } from '@/modules/bff/use-bff-medicines';
import { useAuth } from '@/composables/use-auth';
import MedicineCatalogItem from './MedicineCatalogItem.vue';

const emit = defineEmits(['update:modelValue', 'select']);

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  hideFavorite: {
    type: Boolean,
    default: false,
  },
  hideCustom: {
    type: Boolean,
    default: false,
  },
});

const searchInputRef = ref(null);

const dialogModel = ref(props.modelValue);
watch(() => props.modelValue, (value) => {
  dialogModel.value = value;
});
watch(dialogModel, (value) => {
  emit('update:modelValue', value);
  if (value) {
    nextTick(() => {
      searchInputRef.value.focus();
      init();
    });
  }
}, { immediate: true });

const searchString = ref(null);
const debouncedSearch = debounce(() => {
  init();
}, 300);
watch(searchString, (newValue) => {
  debouncedSearch(newValue);
});

const unifiedBffMedicinesStore = useBffUnifiedMedicinesStore();
const medicines = computed(() => unifiedMedicines.value.medicines?.data);
const medicinesTotal = computed(() => unifiedMedicines.value.medicines?.total);
const favoriteMedicines = computed(() => unifiedMedicines.value.favoriteMedicines?.data);
const favoriteMedicinesTotal = computed(() => unifiedMedicines.value.favoriteMedicines?.total);
const systemMedicines = computed(() => unifiedMedicines.value.systemMedicines?.data);
const systemMedicinesTotal = computed(() => unifiedMedicines.value.systemMedicines?.total);

const {
  loading,
  unifiedMedicines,
} = storeToRefs(unifiedBffMedicinesStore);

const { activeFacility } = useFacilities();
const { currentUser } = useAuth();
async function init () {
  const medicinesQuery = {
    $or: [
      { organization: activeFacility.value?.id },
      { createdBy: currentUser.value?.uid },
    ],
  };
  console.warn('...init');
  await unifiedBffMedicinesStore.unifiedSearch(searchString.value, {
    medicineOpts: medicinesQuery,
  });
  searchInputRef.value.focus();
}

function onSelectMedicine (medicine) {
  dialogModel.value = false;
  emit('select', medicine);
} 
</script>

<template>
  <dialog
    id="dialogModel"
    data-theme="easyjoey"
    :class="`modal ${dialogModel ? 'modal-open' : ''}`"
  >
    <div
      method="dialog"
      class="modal-box relative p-0 max-w-4xl"
    >
      <!-- Dialog content goes here -->
      <div class="flex justify-between gap-2 sticky left-0 right-0 top-0 p-4 bg-white border-b-[1px] z-20">
        <span class="text-xl font-medium">
          Medicine Catalog
        </span>
        <button
          type="button"
          class="btn btn-sm btn-circle btn-ghost"
          @click.stop="dialogModel = false"
        >
          <i class="las la-times text-2xl text-red-400" />
        </button>
      </div>

      <!-- Booking Details Table -->
      <div class="flex flex-col gap-4 p-4">

        <input 
          v-model="searchString" 
          ref="searchInputRef"
          type="text" 
          class="input input-bordered w-full"
          placeholder="Search for medicines..."
          :disabled="loading"
        >

        <div role="tablist" class="tabs tabs-lifted">
          <template v-if="!hideFavorite">
            <input
              type="radio"
              name="medicinesTabs"
              role="tab"
              class="tab"
              style="width: 200px;"
              checked
              :aria-label="`Favorite Medicines (${favoriteMedicinesTotal || 0})`"
            />
            <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-md p-4 h-[500px] overflow-scroll">
              <div class="flex flex-col gap-2">
                <template v-for="medicine in favoriteMedicines">
                  <MedicineCatalogItem
                    :medicine="medicine"
                    favorite
                    @select="onSelectMedicine"
                  />
                </template>
              </div>
            </div>
          </template>

          <template v-if="!hideCustom">
            <input 
              type="radio" 
              name="medicinesTabs" 
              role="tab" 
              class="tab" 
              style="width: 200px;"
              :checked="hideFavorite"
              :aria-label="`My medicines (${medicinesTotal || 0})`" 
            />
            <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-md p-4 h-[500px] overflow-scroll">
              <div class="flex flex-col gap-2">
                <template v-for="medicine in medicines">
                  <MedicineCatalogItem
                    :medicine="medicine"
                    user-medicine
                    @select="onSelectMedicine"
                  />
                </template>
              </div>
            </div>
          </template>

          <input 
            type="radio" 
            name="medicinesTabs" 
            role="tab" 
            class="tab" 
            style="width: 200px;"
            :checked="hideFavorite && hideCustom"
            :aria-label="`System Catalog (${systemMedicinesTotal || 0})`" 
          />
          <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-md p-4 h-[500px] overflow-scroll">
            <div class="flex flex-col gap-2">
              <template v-for="medicine in systemMedicines">
                <MedicineCatalogItem
                  :medicine="medicine"
                  system-default
                  @select="onSelectMedicine"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>