import { ref } from 'vue';
import { defineStore } from 'pinia';

export const createGlobalCache = (id) => defineStore(`cache-${id}`, () => {
  const items = ref(new Map());

  /**
   * @param {string} key
   * @returns {any}
   */
  const get = (key) => {
    if (typeof key !== 'string') throw new Error('key must be a string');
    if (!items.value.has(key)) return;
    const obj = items.value.get(key);
    if (obj.ttl && obj.ttl < Date.now()) {
      unset(key);
      return;
    }
    console.log(`[cache-${id}] hit`, key, obj.data);
    return obj.data;
  };

  /**
   * @param {string} key
   * @param {any} value
   * @param {number} ttl in milliseconds
   */
  const set = (key, data, ttl) => {
    if (typeof key !== 'string') throw new Error('key must be a string');
    const obj = { data };
    if (ttl) obj.ttl = Date.now() + ttl;
    items.value.set(key, obj);
  };

  const unset = (key) => {
    if (typeof key !== 'string') throw new Error('key must be a string');
    items.value.delete(key);
  };

  const clear = () => {
    items.value.clear();
  };

  return {
    items,
    get,
    set,
    unset,
    clear,
  };
});
